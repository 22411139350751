<template>
  <section class="text-center" :style="{ padding: '3rem 0' }">
    <div class="text-center">
      <img src="/images/404.svg" alt="404">
      <h4 :style="{ margin: '2rem 0' }">Страница не найдена</h4>
      <p>Похоже, этой страницы не&nbsp;существует. <br>
        Попробуйте вернуться обратно
        <router-link to="/">к&nbsp;приложению</router-link>
      </p>
    </div>
  </section>
</template>